<template>
  <v-dialog v-model="dialog" persistent :max-width="type ? 400 : 610">
    <template v-slot:activator="{ on, attrs }">
      <v-btn rounded-md class="bg-white" v-bind="attrs" v-on="on" depressed :height="40" block>
        <img class="mx-1" src="@/assets/img/svg/pay1.svg" alt="Qarat-POS" />
        <span class="d-md-block d-none">{{ type ? $t(type) : $t("choose payment method") }}</span>
      </v-btn>
    </template>
    <v-card relative>
      <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
        color="grey lighten-1" text @click="dialog = false">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <v-card-title class="text-center">
        {{ type ? $t(type) : $t("choose payment method") }}
        <v-btn color="error" x-small v-if="type" @click="() => type = null" class="shadow mx-2">{{ $t("Edit") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <div class="d-flex px-5 justify-space-between" v-if="type">
        <v-col cols="auto">
          <v-row>
            <v-col cols="auto">
              <div class="d-block pr-3 pt-3 grey lighten-1 rounded-pill"></div>
            </v-col>
            <v-col cols="auto">
              <h6 class="my-0 py-0">
                {{ $t("theamountrequired") }} :
                <strong>
                  {{ $global.DecimalFormatter(Number(totalPrice.toFixed(2)).toFixed(2)) }}
                </strong>
              </h6>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-row>
            <v-col cols="auto">
              <div class="d-block pr-3 pt-3 grey lighten-1 rounded-pill"></div>
            </v-col>
            <v-col cols="auto">
              <h6 class="my-0 py-0"
                :style="$global.DecimalFormatter(remaining) > 0 ? 'color: #d50000' : 'color: #0bac30'">
                <span v-if="$global.DecimalFormatter(remaining) > 0">{{ $t("remining") }} :</span>
                <span v-if="$global.DecimalFormatter(remaining) < 0">{{ $t("more") }} :</span>
                <span v-if="$global.DecimalFormatter(remaining) == 0">{{ $t("ok") }} :</span>
                <strong>{{ $global.DecimalFormatter(remaining) }}</strong>
              </h6>
            </v-col>
          </v-row>
        </v-col>
      </div>

      <v-col cols="12" v-if="!type">
        <v-row justify="center" class="pa-4">
          <v-col cols="3" v-for="(type, index) in paymentsTypes" :key="index">
            <v-card :disabled="type.disabled" outlined height="120"
              class="ma-auto overflow-hidden rounded-xl d-flex justify-center align-center mt-2"
              @click="changeType(type.id)" width="120" :title="$t(type.id)">
              <div>
                <img :src="type.image" height="95" width="95" :alt="$t(type.name)" class="d-block mb-1 ma-auto">
                <div class="subtitle-2 mt-n2 text-center">
                  {{ type.disabled ? $t('(please choose machine)') : $t(type.name) }}
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-form class="px-5 pb-5" onsubmit="return false;" v-if="type">
        <v-col cols="12" sm="12">
          <v-col cols="12" class="py-0 mb-2 justify-center d-flex" v-if="activeStep == 'bankNumberPad'">
            <v-btn depressed @click="card_type = 3" text min-width="100" class="mx-2"
              :class="card_type == 3 ? 'gray11' : ''" color="primary">
              <v-icon left color="success" v-if="card_type == 3">mdi-check</v-icon>
              <v-icon left color="success" v-else></v-icon>
              <img src="@/assets/img/svg/mada.svg" height="20" alt="visa">
            </v-btn>
            <v-btn depressed @click="card_type = 1" text min-width="100" class="mx-2"
              :class="card_type == 1 ? 'gray11' : ''" color="primary">
              <v-icon left color="success" v-if="card_type == 1">mdi-check</v-icon>
              <v-icon left color="success" v-else></v-icon>
              <img src="@/assets/img/svg/visa.svg" height="20" alt="visa">
            </v-btn>
            <v-btn depressed @click="card_type = 2" text min-width="100" class="mx-2"
              :class="card_type == 2 ? 'gray11' : ''" color="primary">
              <v-icon left color="success" v-if="card_type == 2">mdi-check</v-icon>
              <v-icon left color="success" v-else></v-icon>
              <img src="@/assets/img/svg/mastercard.svg" height="30" alt="visa">
            </v-btn>

          </v-col>
          <h5 class="mb-5" v-if="activeStep == 'cashNumberPad'">{{ $t('please enter the amount') }}</h5>
          <h5 class="mb-5" v-if="activeStep == 'bankNumberPad'">{{ $t("pleaseEnterTheAmountPaidByTheNetwork") }}</h5>
          <h5 class v-if="activeStep == 'transferNumberPad'">{{ $t("pleaseEnterTheAmountPaidByTheTransfer") }}</h5>

          <NumberPad v-if="transferNumberPad == true && activeStep == 'transferNumberPad'"
            :backValueMethod="setTransferPrice" :value="String(+transferPrice.toFixed(2))"></NumberPad>
          <NumberPad v-if="bankNumberPad == true && activeStep == 'bankNumberPad'" :backValueMethod="setBankPrice"
            :value="String(+bankPrice.toFixed(2))"></NumberPad>
          <NumberPad v-if="cashNumberPad == true && activeStep == 'cashNumberPad'" :backValueMethod="setPrice"
            :value="String(+price.toFixed(2))"></NumberPad>
        </v-col>
        <v-row justify="end" class="mb-1">
          <v-col cols="auto" class="px-5 py-0" v-if="stepTwo && activeStep != stepTwo">
            <v-btn color="success" block large width="115px" @click="nextStep" class="shadow">{{ $t("next") }}</v-btn>
          </v-col>
          <v-col :cols="!stepTwo ? 'auto' : 'auto'" class="px-5 py-0" v-if="!stepTwo || (activeStep == stepTwo)">
            <v-btn color="success" large block width="115px" :disabled="!valid" @click="submitPayment" class="shadow">
              {{ $t("ok") }}</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>






<script>
import NumberPad from "../NumberPad.vue";
export default {
  name: "PaymentMethodPurchase",
  props: {
    backValueMethod: { default: Function },
    getTypeMethod: { default: Function },
    type: { default: "" },
    machineID: { default: "" },
    bankID: { default: "" },
    totalPrice: { default: 0 }
  },
  watch: {
    type() {
      this.price = 0;
      this.bankPrice = 0;
      this.transferPrice = 0;
      if (this.type == 'payTamara' || this.type == 'payTabby') {
        this.setPrice(+this.remaining)
      }
    },
    activeStep() {
      if (this.activeStep == 'bankNumberPad' && this.type !== 'paymentTransferAndBank') {
        this.setBankPrice(+this.remaining)
      }
      if (this.activeStep == 'transferNumberPad') {
        this.setTransferPrice(+this.remaining)
      }
      if (this.activeStep == 'cashNumberPad' && this.type !== 'payCashBank' && this.type !== 'transferAndCashPayment') {
        this.setPrice(+this.remaining)
      }
    }
  },
  mounted() {
    window.addEventListener("keydown", e => {
      if (e.altKey == true && e.code == "KeyP") {
        this.dialog = true;
      }
      if (e.code == 'Escape') {
        this.dialog = false;
      }
    });
  },
  computed: {
    valid() {
      const totalAmount = this.totalPrice;
      if (totalAmount) {
        switch (this.type) {
          case "payCash":
            return totalAmount <= +this.price;
          case "payCashBank":
            return totalAmount === +this.price + +this.bankPrice;
          case "payBank":
            return totalAmount === +this.bankPrice;
          case "transferPayment":
            return totalAmount === +this.transferPrice;
          case "transferAndCashPayment":
            return totalAmount === +this.price + +this.transferPrice;
          case "paymentTransferAndBank":
            return totalAmount === +this.bankPrice + +this.transferPrice;
          default: return false;
        }
      } else {
        return false;
      }
    },
    isRTL() {
      return this.$store.state.Settings.isRTL;
    },
    remaining() {
      return Number(+this.totalPrice.toFixed(2) - (+this.price + +this.bankPrice + +this.transferPrice)).toFixed(2)
    },
    paymentsTypes() {
      return [
        { id: 'payCash', name: this.$t('payCash'), image: require('@/assets/img/payment/cash.svg') },
        { id: 'payBank', name: this.$t('payBank'), image: require('@/assets/img/payment/bank.svg'), disabled: !this.machineID },
        { id: 'payCashBank', name: this.$t('payCashBank'), image: require('@/assets/img/payment/cash_bank.svg'), disabled: !this.machineID },
        { id: 'transferPayment', name: this.$t('transferPayment'), image: require('@/assets/img/payment/transfer.svg') },
        { id: 'transferAndCashPayment', name: this.$t('transferAndCashPayment'), image: require('@/assets/img/payment/cash_transfer.svg') },
        { id: 'paymentTransferAndBank', name: this.$t('paymentTransferAndBank'), image: require('@/assets/img/payment/bank_transfer.svg'), disabled: !this.machineID },
      ]
    }
  },
  components: {
    NumberPad,
  },
  methods: {
    changeType(id) {
      this.type = id;
      this.getTypeMethod(id) 
      this.checkpayment_type()
    },
    checkpayment_type() {
      switch (this.type) {
        case "payCash":
          this.activeStep = "cashNumberPad";
          this.stepOne = "cashNumberPad";
          this.stepTwo = null
          this.cashNumberPad = true
          break;
        case "payCashBank":
          this.activeStep = "cashNumberPad";
          this.stepOne = "cashNumberPad";
          this.stepTwo = "bankNumberPad";
          this.cashNumberPad = true
          this.bankNumberPad = true;
          break;
        case "payBank":
          this.activeStep = "bankNumberPad";
          this.stepOne = "bankNumberPad";
          this.stepTwo = null;
          this.bankNumberPad = true
          break;
        case "transferPayment":
          this.activeStep = "transferNumberPad";
          this.stepOne = "transferNumberPad";
          this.stepTwo = null;
          this.transferNumberPad = true
          break;
        case "transferAndCashPayment":
          this.activeStep = "cashNumberPad";
          this.stepOne = "cashNumberPad";
          this.stepTwo = "transferNumberPad";
          this.cashNumberPad = true
          this.transferNumberPad = true;
          break;
        case "paymentTransferAndBank":
          this.activeStep = "bankNumberPad";
          this.stepOne = "bankNumberPad";
          this.stepTwo = "transferNumberPad";
          this.bankNumberPad = true
          this.transferNumberPad = true;
          break;
        default:
          return false;
      }
    },
    nextStep() {
      this.activeStep = this.stepTwo
    },
    prevStep() {
      this.activeStep = this.stepOne
    },
    submitPayment() {
      var payment = [];

      switch (this.type) {
        case "payCash":
          payment = [
            {
              machine_id: 0,
              bank_id : 0,
              amount: +this.price,
              payment_type: 1,
              payment_title: "نقدي",
            }
          ]
          break;
        case "payCashBank":
          payment = [
            {
              machine_id: 0,
              bank_id : 0,
              amount: +this.price,
              payment_type: 1,
              card_type: this.card_type,
              payment_title: "شبكه ونقدي",
            },
            {
              machine_id: this.machineID,
              bank_id : 0,
              amount: +this.bankPrice,
              payment_type: 2,
              card_type: this.card_type,
              payment_title: "شبكه ونقدي",
            }
          ]
          break;
        case "payBank":
          payment = [
            {
              machine_id: this.machineID,
              bank_id : 0,
              amount: +this.bankPrice,
              payment_type: 2,
              payment_title: "شبكه",
              card_type: this.card_type,
            }
          ]
          break;
        case "transferPayment":
          payment = [
            {
              machine_id: 0,
              bank_id: this.bankID,
              amount: +this.transferPrice,
              payment_type: 3,
              payment_title: "حواله",
            }
          ]
          break;
        case "transferAndCashPayment":
          payment = [
            {
              machine_id: 0,
              bank_id : 0,
              amount: +this.price,
              payment_type: 1,
              payment_title: "حواله ونقدي",
            },
            {
              machine_id: 0,
              amount: +this.transferPrice,
              payment_type: 3,
              bank_id: this.bankID,
              payment_title: "حواله ونقدي",
            }
          ]
          break;
        case "paymentTransferAndBank":
          payment = [
            {
              machine_id: 0,
              amount: +this.transferPrice,
              payment_type: 3,
              bank_id: this.bankID,
              payment_title: "حواله وشبكة",
              card_type: this.card_type,
            },
            {
              machine_id: this.machineID,
              amount: +this.bankPrice,
              bank_id : 0,
              payment_type: 2,
              payment_title: "حواله ونقدي",
            }
          ]
          break;
        default:
          break;
      }
      this.backValueMethod(payment);
      this.dialog = false;
      this.setCartType(this.card_type)
    },
    setPrice(returnedPrice) {
      this.price = +returnedPrice;
    },
    setBankPrice(returnedPrice) {
      this.bankPrice = +returnedPrice;
    },
    setTransferPrice(returnedPrice) {
      this.transferPrice = +returnedPrice;
    },
  },
  data() {
    return {
      dialog: false,
      price: 0,
      bankPrice: 0,
      card_type: 1,
      transferPrice: 0,
      cashNumberPad: false,
      bankNumberPad: false,
      transferNumberPad: false,
      stepOne: null,
      stepTwo: null,
      stepOnePrice: 0,
      stepTwoPrice: 0,
      activeStep: '',
    };
  },
};
</script>
